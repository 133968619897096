
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import SchemaData from "../components/schema-data/schemaData.tsx";
import { useRef, useEffect } from "react";
import Script from 'next/script';
function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const pid = router.asPath;
    const scrollRef = useRef({
        scrollPos: 0,
    });
    return (<>
      <Meta title="Sofia Airport Transfers BG Taxi Services"/>
      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute="class">
          <UserContext.Provider value={{ scrollRef: scrollRef }}>
            <SchemaData></SchemaData>
            {pid === "/login" ? (<Component {...pageProps}/>) : (<Layout>
                <Component {...pageProps}/>
              </Layout>)}
          </UserContext.Provider>
        </ThemeProvider>
      </Provider>
    </>);
}
const __Next_Translate__Page__18f40739e65__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__18f40739e65__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  